<template>
  <vs-card class="p-3 py-6 md:p-6" :showLoading="cardLoading">
    <form
      class="vx-row w-full items-center"
      id="grading-round-form"
      @submit="submit()"
      @keyup.enter="submit">
      <!-- Name  -->
      <div class="vx-col lg:w-2/3 w-full mb-5">
        <label class="text-sm"
          >{{ $t('grading.title_for_the_report_card') }}
          <sup class="text-danger">*</sup>
        </label>
        <vs-input
          v-model="round.title"
          class="w-full"
          v-validate="'required|max:60'"
          name="name"
          type="text"
          :description-text="$t('form.max_60_characters')"
          :readonly="!checkIfTodayOrAfter(round.display_date)" />
        <span class="text-danger text-sm">{{
          errors.first('name') | text_formatter
        }}</span>
      </div>
      <!-- name info -->
      <div class="vx-col lg:w-1/3 w-full">
        <p class="w-full text-xs mb-0">
          {{
            $t(
              'grading.the_title_will_be_displayed_on_the_top_of_the_report_card'
            )
          }}
        </p>
      </div>

      <!-- Allocations -->
      <div class="vx-col lg:w-2/3 w-full mb-5">
        <label class="text-sm"
          >{{ $t('grading.choose_allocation') }}
          <sup class="text-danger">*</sup>
        </label>

        <v-select
          class="w-full"
          v-model="selectedAllocation"
          name="allocation"
          v-validate="'required'"
          label="name"
          :options="allocations"
          :showLoadingIcon="allocationLoading"
          :disabled="isUpdate" />
        <span class="text-danger text-sm">{{
          errors.first('allocation') | text_formatter
        }}</span>
      </div>

      <!--//////////////////////////////
                     Dates
      /////////////////////////////////-->
      <!-- covered_period -->
      <div class="vx-col lg:w-2/3 w-full mb-5">
        <label class="text-sm"
          >{{ $t('grading.covered_period') }}<sup class="text-danger">*</sup>
        </label>
        <div class="flex justify-between flex-wrap">
          <div class="md:flex-1 w-full mb-2">
            <flat-pickr
              :placeholder="$t('basic.startDate')"
              class="w-full"
              :config="{
                minDate: allocationStartDate,
                maxDate: allocationEndDate
              }"
              v-model="round.start_date"
              name="covered_period_start_date"
              v-validate="'required'"
              :disabled="
                !allocationStartDate || !canChangeCoveredPeriodStartDate
              " />
            <span class="text-danger text-sm">{{
              errors.first('covered_period_start_date') | text_formatter
            }}</span>
          </div>
          <div class="md:flex-1 w-full mb-2 md:mx-1">
            <flat-pickr
              :placeholder="$t('basic.endDate')"
              class="w-full"
              :config="{
                minDate: round.start_date,
                maxDate: allocationEndDate
              }"
              v-model="round.end_date"
              name="covered_period_end_date"
              v-validate="{required: true, is_not: round.start_date}"
              :disabled="
                !allocationStartDate || !canChangeCoveredPeriodEndDate
              " />
            <span class="text-danger text-sm">{{
              errors.first('covered_period_end_date') | text_formatter
            }}</span>
          </div>
        </div>
      </div>
      <!-- Display Date -->
      <div class="vx-col lg:w-2/3 w-full mb-5">
        <label class="text-sm"
          >{{ $t('grading.display_date') }}<sup class="text-danger">*</sup>
        </label>
        <flat-pickr
          class="w-full"
          :config="{minDate: round.start_date, maxDate: round.end_date}"
          v-model="round.display_date"
          name="display_date"
          :disabled="disabledDisplayDate"
          v-validate="'required'" />
        <span class="text-danger text-sm">{{
          errors.first('display_date') | text_formatter
        }}</span>
      </div>
      <!-- Display Date info -->
      <div class="vx-col lg:w-1/3 w-full">
        <p class="w-full text-xs mb-0">
          {{
            $t('grading.date_to_activate_the_function_of_Update_student_card')
          }}
        </p>
      </div>
      <!-- Submission Date-->
      <div class="vx-col lg:w-2/3 w-full mb-5">
        <label class="text-sm"
          >{{ $t('grading.submission_date_closing_date')
          }}<sup class="text-danger">*</sup>
        </label>
        <flat-pickr
          class="w-full"
          :config="{minDate: round.display_date, maxDate: null}"
          v-model="round.closing_date"
          name="closing_date"
          :disabled="disabledSubmissionDate"
          v-validate="'required'" />
        <span class="text-danger text-sm">{{
          errors.first('closing_date') | text_formatter
        }}</span>
      </div>
      <!-- Submission Date info -->
      <div class="vx-col lg:w-1/3 w-full">
        <p class="w-full text-xs mb-0">
          {{
            $t('grading.date_to_activate_the_function_of_Update_student_card')
          }}
        </p>
      </div>
      <!-- ///////////////////////////////
                Settings
       ///////////////////////////////////-->
      <vs-divider position="center" class="vx-col lg:w-2/3 w-full mb-5">{{
        $t('basic.settings')
      }}</vs-divider>
      <!-- report card types -->
      <div class="vx-col lg:w-2/3 w-full mb-5">
        <label class="w-full">{{ $t('grading.set_as') }}</label>
        <div class="flex flex-wrap flex-col md:flex-row justify-start">
          <vs-radio
            class="m-2"
            v-model="round.is_final"
            vs-name="report_card_type"
            :vs-value="true"
            :disabled="isDisplayDateCame"
            >{{ $t('grading.normal_report_card') }}</vs-radio
          >
          <v-select
            class="md:w-1/4 w-full"
            v-model="round.term"
            name="term"
            label="name"
            :reduce="(term) => term.name"
            :options="terms"
            :showLoadingIcon="termsLoading"
            :placeholder="$t('grading.link_with_term')"
            v-if="round.is_final"
            :disabled="isDisplayDateCame" />
        </div>

        <div class="flex flex-wrap flex-col md:flex-row justify-start">
          <vs-radio
            class="m-2"
            v-model="round.is_final"
            vs-name="report_card_type"
            :vs-value="false"
            :disabled="isDisplayDateCame"
            >{{ $t('grading.final_report_card') }}</vs-radio
          >
        </div>
        <span class="text-danger text-sm">{{
          errors.first('report_card_type') | text_formatter
        }}</span>
      </div>
      <!-- include -->
      <div class="vx-col w-full mb-5">
        <vs-checkbox
          :disabled="isDisplayDateCame"
          v-model="round.allow_section_coordinator_to_update_a_general_comment">
          {{
            $t('grading.allow_section_coordinator_to_update_a_general_comment')
          }}
        </vs-checkbox>
      </div>
      <div class="vx-col lg:w-1/3 md:w-2/3 w-full mb-5">
        <vs-checkbox
          :disabled="isDisplayDateCame"
          v-model="round.allow_teachers_to_update_a_subject_based_comment">
          {{ $t('grading.allow_teachers_to_update_a_subject_based_comment') }}
        </vs-checkbox>
      </div>
      <!-- info -->
      <div class="vx-col lg:w-2/3 md:w-1/3 w-full mb-5">
        <p class="w-full text-xs">
          <!-- {{ $t("grading.if_not_then_section_coordinators_will_manage_it") }} -->
        </p>
      </div>
      <div class="vx-col w-full mb-5">
        <vs-checkbox
          :disabled="isDisplayDateCame || isDP"
          v-model="
            round.display_the_boundaries_and_descriptors_in_the_report_card
          ">
          {{
            $t(
              'grading.display_the_boundaries_and_descriptors_in_the_report_card'
            )
          }}
        </vs-checkbox>
      </div>
      <div class="vx-col lg:w-1/3 md:w-2/3 w-full mb-5">
        <vs-checkbox
          :disabled="isDisplayDateCame"
          v-model="round.display_the_local_grade_in_the_report_card">
          {{ $t('grading.display_the_local_grade_in_the_report_card') }}
        </vs-checkbox>
      </div>
      <!-- info -->
      <div class="vx-col lg:w-2/3 md:w-1/3 w-full mb-5">
        <p class="w-full text-xs">
          {{
            $t(
              'grading.be_sure_that_the_local_grade_is_already_built_in_the_boundaries'
            )
          }}
        </p>
      </div>
      <div class="vx-col w-full mb-5">
        <vs-checkbox
          :disabled="isDisplayDateCame"
          v-model="round.display_the_achieved_grade_beside_the_boundary">
          {{
            $t('grading.display_the_achieved_grade_next_to_the_boundary')
          }}</vs-checkbox
        >
      </div>
      <!-- IF IB   -->
      <template v-if="isIB">
        <!-- ATL -->
        <template>
          <div class="vx-col lg:w-1/3 md:w-2/3 w-full mb-5">
            <vs-checkbox
              :disabled="isDisplayDateCame"
              v-model="round.include_ATL_in_the_report_card"
              >{{ $t('grading.include_ATL_in_the_report_card') }}</vs-checkbox
            >
          </div>
          <!-- info -->
          <div class="vx-col lg:w-2/3 md:w-1/3 w-full mb-5">
            <p class="w-full text-xs">
              {{
                $t(
                  'grading.then_teacher_must_update_the_ATL_for_each_student_subject_exclusive_for_IB'
                ) | text_formatter
              }}
            </p>
          </div>
        </template>
        <template>
          <div class="vx-col lg:w-1/3 md:w-2/3 w-full mb-5">
            <vs-checkbox
              :disabled="isDisplayDateCame"
              v-model="round.include_IDU_in_the_report_card">
              {{
                $t('grading.include_IDU_in_the_report_card') | text_formatter
              }}</vs-checkbox
            >
          </div>
          <!-- info -->
          <div class="vx-col lg:w-2/3 md:w-1/3 w-full mb-5">
            <p class="w-full text-xs">
              {{
                $t(
                  'grading.then_the_related_teachers_must_update_it__for_each_student_exclusive_for_IB'
                ) | text_formatter
              }}
            </p>
          </div>
        </template>
        <!--      the_participation_in_the_report_card  /-->
        <template>
          <div class="vx-col lg:w-1/3 md:w-2/3 w-full mb-5">
            <vs-checkbox
              :disabled="isDisplayDateCame"
              v-model="round.include_the_participation_in_the_report_card">
              {{
                $t('grading.include_the_participation_in_the_report_card')
                  | text_formatter
              }}</vs-checkbox
            >
          </div>
          <!-- info -->
          <div class="vx-col lg:w-2/3 md:w-1/3 w-full mb-5">
            <p class="w-full text-xs">
              {{
                $t(
                  'grading.then_the_related_teacher_must_update_it_for_each_student_exclusive_for_IB'
                ) | text_formatter
              }}
            </p>
          </div>
        </template>

        <template>
          <div class="vx-col lg:w-1/3 md:w-2/3 w-full mb-5">
            <vs-checkbox
              :disabled="isDisplayDateCame"
              v-model="round.include_CAS_TOK_EE_in_the_report_card"
              >{{
                $t('grading.include_CAS_TOK_EE_in_the_report_card')
                  | text_formatter
              }}
            </vs-checkbox>
          </div>
          <!-- info -->
          <div class="vx-col lg:w-2/3 md:w-1/3 w-full mb-5 mb-5">
            <p class="w-full text-xs">
              {{ $t('grading.exclusive_for_IB') | text_formatter }}
            </p>
          </div>
        </template>
        <!-- POI -->
        <template v-if="isPYP">
          <div class="vx-col lg:w-1/3 md:w-2/3 w-full mb-5">
            <vs-checkbox
              :disabled="isDisplayDateCame"
              v-model="round.include_POI_in_the_report_card"
              >{{ $t('grading.include_poi') | text_formatter }}
            </vs-checkbox>
          </div>
          <!-- info -->
          <div class="vx-col lg:w-2/3 md:w-1/3 w-full mb-5 mb-5">
            <p class="w-full text-xs">
              {{ $t('grading.exclusive_for_IB') | text_formatter }}
            </p>
          </div>
        </template>
      </template>

      <!-- ////////////////////
                  Actions
      //////////////////////////-->
      <div class="flex justify-between flex-wrap w-full mb-5">
        <vs-button @click="cancel" color="warning" type="border" class="mb-5">{{
          $t('form.cancel')
        }}</vs-button>
        <vs-button
          @click="submit"
          color="primary"
          type="filled"
          class="mb-5 mr-0 ml-auto"
          >{{ isUpdate ? $t('form.update') : $t('form.submit') }}</vs-button
        >
      </div>
    </form>
  </vs-card>
</template>
<script>
import {commonDataMixin} from '@/mixins.js'
import {RepositoryFactory} from '@/Repositories/RepositoryFactory'
const Repository = {
  allocations: RepositoryFactory.get('GradingModule', 'allocations'),
  rounds: RepositoryFactory.get('GradingModule', 'rounds')
}
export default {
  name: 'grading-round-form',
  mixins: [commonDataMixin],
  data() {
    return {
      ////////////////////////
      // Loading
      ////////////////////////
      allocationLoading: false,
      termsLoading: false,
      cardLoading: false,
      ////////////////////////
      // Data
      ////////////////////////
      allocations: [],

      grading_management: [],
      ////////////////////////
      // Datepicker config
      ////////////////////////
      configCoverdPeriodDatePicker: {
        minDate: null
      },
      ////////////////////////
      // Round Obj
      ////////////////////////
      selectedAllocation: null,
      round: {
        title: null,
        grading_allocation_id: null,
        start_date: null,
        end_date: null,
        closing_date: null,
        display_date: null,
        is_final: true,
        term: null, // term_name
        allow_section_coordinator_to_update_a_general_comment: true,
        allow_teachers_to_update_a_subject_based_comment: true,
        display_the_boundaries_and_descriptors_in_the_report_card: true,
        display_the_local_grade_in_the_report_card: false,
        display_the_achieved_grade_beside_the_boundary: false,
        include_ATL_in_the_report_card: false,
        include_IDU_in_the_report_card: false,
        include_the_participation_in_the_report_card: false,
        include_CAS_TOK_EE_in_the_report_card: false,
        include_POI_in_the_report_card: false
      },
      ///////////////////////
      // Edit
      ///////////////////////
      firstAssignData: false,
      editRoundObj: {}
    }
  },
  computed: {
    grade() {
      return this.grading_management[0] || null
    },
    isIB() {
      return Boolean(
        this.grade &&
          (this.grade.division_id === 1 || this.grade.division_name === 'IB')
      )
    },
    isPYP() {
      return Boolean(
        this.isIB &&
          this.grade &&
          (this.grade.section_id === 1 || this.grade.section_name === 'PYP')
      )
    },
    isDP() {
      return Boolean(
        this.isIB &&
          this.grade &&
          (this.grade.section_id === 3 || this.grade.section_name === 'DP')
      )
    },
    roundId() {
      return this.$route.params.id || null
    },
    allocationStartDate() {
      return this.selectedAllocation?.start_date || null
    },
    allocationEndDate() {
      return this.selectedAllocation?.end_date || null
    },
    isUpdate() {
      return Boolean(this.roundId)
    },
    isUpcoming() {
      return this.round.time_status === 'upcoming'
    },
    isRunning() {
      return this.round.time_status === 'running'
    },
    canChangeCoveredPeriod() {
      // return this.isUpdate ? this.isUpcoming   : true
      return false
    },

    canChangeCoveredPeriodStartDate() {
      // return this.isUpdate ? this.canChangeCoveredPeriod && !this.checkIfTodayOrAfter(this.round.start_date) : true
      return true
    },
    canChangeCoveredPeriodEndDate() {
      // return this.isUpdate ? this.canChangeCoveredPeriod && !this.checkIfTodayOrAfter(this.round.end_date) : true
      return true
    },
    disabledDisplayDate() {
      // return  this.isDisplayDateCame || (!this.round.start_date ||!this.round.end_date)
      return false
    },
    isDisplayDateCame() {
      // return this.isUpdate ? this.checkIfTodayOrAfter(this.round.display_date) : false
      return false
    },
    disabledSubmissionDate() {
      // return  this.isUpdate ? this.checkIfTodayOrAfter(this.round.closing_date) : !this.round.display_date || !this.round.end_date
      return false
    },
    terms() {
      return this.currentAcademicYearData.simpleTermsArr
    }
  },
  methods: {
    //////////////////////////
    // Get Data APIs
    //////////////////////////
    getAllAllocations() {
      this.allocationLoading = true
      Repository.allocations
        .getAllocationWithoutPagination()
        .then((res) => {
          this.allocations = res.data
          if (this.isUpdate && !this.selectedAllocation?.id)
            this.setSelectedAllocations()
        })
        .catch(this.errMsg)
        .finally(() => {
          this.allocationLoading = false
        })
    },
    /////////////////////////
    //  Grading round
    //////////////////////////

    createGradingRound() {
      this.cardLoading = true
      Repository.rounds
        .createGradingRound(this.handleRoundPayload())
        .then(() => {
          this.successMsg()
          this.back()
        })
        .catch(this.errMsg)
        .finally(() => {
          this.cardLoading = false
        })
    },
    showGradingRound() {
      this.cardLoading = true
      this.firstAssignData = true
      Repository.rounds
        .showGradingRound(this.roundId)
        .then((res) => this.handleShowRoundPayload(res.data))
        .catch(this.errMsg)
        .finally(() => {
          this.cardLoading = false
        })
    },
    UpdateGradingRound() {
      this.cardLoading = true
      Repository.rounds
        .UpdateGradingRound(this.roundId, this.handleRoundPayload())
        .then(() => {
          this.successMsg()
          this.back()
        })
        .catch(this.errMsg)
        .finally(() => {
          this.cardLoading = false
        })
    },
    /////////////////////////
    // form Actions
    //////////////////////////
    back() {
      this.$router.push({name: 'gradingRounds'})
    },
    cancel() {
      this.dangerDialog(
        null,
        this.$t('grading.all_changes_will_be_lost_Are_you_sure'),
        this.back
      )
    },
    submit() {
      this.$validator
        .validateAll()
        .then((valid) => {
          if (valid) {
            if (this.roundId) {
              this.confirmDialog(
                null,
                this.$t(
                  'grading.edits_will_be_applied_only_on_the_new_generated_report_cards'
                ),
                this.UpdateGradingRound,
                'warning'
              )
            } else this.createGradingRound()
          } else this.errMsg()
        })
        .catch(this.errMsg)
    },
    /////////////////////////
    // Data
    //////////////////////////
    // Reset Dates
    resetDates() {
      // this.round.date
    },
    /////////////////////////
    // Handle Data
    //////////////////////////
    handleRoundPayload() {
      if (this.round.is_final === null) this.round.is_final = true
      const payload = Object.assign({}, this.round)
      payload.grading_allocation_id = this.selectedAllocation.id
      return payload
    },
    async handleShowRoundPayload(payload) {
      this.editRoundObj = payload
      for (const key in payload) {
        await (this.round[key] = payload[key])
      }
      this.round.time_status = payload.time_status
      this.round.status = payload.status
      if (this.allocations.length) {
        this.setSelectedAllocations()
      }
      this.grading_management = payload.grading_management
      this.firstAssignData = false
    },
    setSelectedAllocations() {
      this.selectedAllocation = this.allocations.find(
        (e) => e.id === this.editRoundObj.grading_allocation_id
      )
    },
    ///////////////////////
    // Check Data
    ///////////////////////
    checkIfTodayOrAfter(date) {
      return new Date() >= new Date(date)
    }
  },
  watch: {
    'round.start_date'() {
      if (!this.firstAssignData) {
        this.round.end_date =
          this.round.display_date =
          this.round.closing_date =
          this.round.release_date =
            null
      }
    },
    'round.end_date'() {
      if (!this.firstAssignData) {
        this.round.display_date =
          this.round.closing_date =
          this.round.release_date =
            null
      }
    },
    'round.display_date'() {
      if (!this.firstAssignData) {
        this.round.closing_date = this.round.release_date = null
      }
    },
    'round.closing_date'() {
      if (!this.firstAssignData) {
        this.round.release_date = null
      }
    },
    selectedAllocation(v) {
      this.grading_management = v.grading_management
      if (this.isDP) {
        this.round.display_the_boundaries_and_descriptors_in_the_report_card = false
      }
    }
  },
  created() {
    this.getAllAllocations()
    if (this.roundId) {
      this.showGradingRound()
    }
  }
}
</script>

<style scoped>
@media (max-width: 440px) {
  .con-vs-checkbox {
    display: block;
  }
}
</style>
